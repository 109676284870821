import { css, Global, useTheme } from '@emotion/react';
import { commonThemeGlobals, generateGrid, commonScrollbar, globals } from 'common-ui';
import emotionNormalize from 'emotion-normalize';

export const globalcss = (theme: ReturnType<typeof useTheme>) => {
  const gridClasses = generateGrid(theme);

  return css`
    * {
      box-sizing: border-box;
    }

    html.vine-react-app,
    body.vine-react-app {
      padding: 0;
      margin: 0;
      min-height: 100%;
      font-family: ${theme.fonts.familySecondary};
      font-size: ${theme.fonts.sizeRoot};
      ${commonScrollbar(theme)};
    }

    // * '.vine-react-app' is a class used in the '<body>' of a Next.js app and embedded apps roots (header and footer).
    .vine-react-app {
      //icon fonts generated with 'iconbuild'
      @font-face {
        font-family: 'icons';
        src:
          url('${globals.assetsPath}/next/fonts/icons.ttf?v=${globals.buildVersion}') format('truetype'),
          url('${globals.assetsPath}/next/fonts/icons.woff?v=${globals.buildVersion}') format('woff'),
          url('${globals.assetsPath}/next/fonts/icons.woff2?v=${globals.buildVersion}') format('woff2');
      }

      ${emotionNormalize}
      ${commonThemeGlobals}
      ${gridClasses}
      table {
        border-collapse: collapse;
      }

      // Utility Classes
      .h1,
      h1,
      .custom-h2-header {
        ${theme.mixins.h1}
      }
      .h2,
      h2 {
        ${theme.mixins.h2}
      }
      .h3,
      h3 {
        ${theme.mixins.h3}
      }
      .h4,
      h4 {
        ${theme.mixins.h4}
      }
      small {
        font-family: ${theme.fonts.familySecondary};
        font-size: ${theme.fonts.sizeSmall};
      }
      a {
        ${theme.mixins.linkPrimary}
      }
      a.secondary {
        ${theme.mixins.linkSecondary}
      }
      a.tertiary {
        ${theme.mixins.linkTertiary}
      }
      // OSANO
      .osano-cm-widget {
        display: none;
      }
      .hxl {
        ${theme.mixins.hxl}
      }
      .h5,
      h5 {
        ${theme.mixins.h5}
      }
      .h6,
      h6 {
        ${theme.mixins.h6}
      }
      .h7 {
        ${theme.mixins.h7}
      }
      .h8 {
        ${theme.mixins.h8}
      }
      .eyebrowRegular {
        ${theme.mixins.eyebrowRegular}
      }
      .eyebrowSmall {
        ${theme.mixins.eyebrowSmall}
      }
      .utility {
        ${theme.mixins.utility}
      }
      .quote,
      blockquote {
        ${theme.mixins.quote}
      }
      .caption {
        ${theme.mixins.caption}
      }
      .body1,
      p {
        ${theme.mixins.body1}
        &.strikeThrough {
          text-decoration: ${theme.fonts.caseStrikeThrough};
        }
        &.bold {
          font-weight: ${theme.fonts.weightBold};
        }
      }
      .body2 {
        ${theme.mixins.body2}
        &.strikeThrough {
          text-decoration: ${theme.fonts.caseStrikeThrough};
        }
        &.bold {
          font-weight: ${theme.fonts.weightBold};
        }
      }
      .bodySmall {
        ${theme.mixins.bodySmall}
        &.strikeThrough {
          text-decoration: ${theme.fonts.caseStrikeThrough};
        }
        &.bold {
          font-weight: ${theme.fonts.weightBold};
        }
      }
    }
    .enabled-vine-header {
      .aa-Panel {
        background: ${theme.colors.white};
        clip-path: inset(0 -100vmax);
        z-index: 999;
        box-shadow: 0 0 0 100vmax ${theme.colors.white};
        margin: 0;
        height: 100%;
        padding: 0 13px 67px;
        width: 100% !important;
        ${theme.breakpoints.lg} {
          height: auto;
          padding: 0;
        }
      }
      .aa-PanelLayout {
        max-height: 100%;
        flex-direction: column;
        ${theme.breakpoints.lg} {
          max-height: initial;
        }
      }
      .aa-SourceHeader {
        border-bottom: 0;
      }
    }
    .vine-react-app.enabled-vine-header .aa-Panel {
      width: 100% !important;
      ${theme.breakpoints.lg} {
        width: calc(100% + 400px) !important;
      }
    }

    .LearnMore-modal {
      .mantine-Modal-inner {
        .mantine-Modal-body {
          padding: 20px;
          padding-top: 25px;

          .modalTitle2 {
            margin-bottom: 20px;
          }

          .divider {
            border-top-color: #616161;
            margin-bottom: 20px;
          }
        }

        .learnMore-header {
          position: relative;

          .learnMore-closeBtn {
            position: absolute;
            top: 0;
            right: 0;
            i {
              font-size: 20px;
            }
          }
        }

        .learnMore-image-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .mantine-ThemeIcon-root {
          color: #006e1a;
        }

        .cart-button button {
          border-radius: 4px;
        }

        .cart-button-wrapper {
          margin-top: 20px;
        }
      }
    }
  `;
};

const GlobalStyles = () => {
  const theme = useTheme();

  const globalStyles = globalcss(theme);
  return <Global styles={globalStyles} />;
};

export default GlobalStyles;
